import React, {PureComponent} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import green from '../assets/img/dashboard/Green_U.png'
import red from '../assets/img/dashboard/Red_D.png'
import amber from '../assets/img/dashboard/Amber_N.png'


const dataa = [
    {name: 'Growth Order', value: 0},
    {name: 'Growth Amount', value: 0}
];


class GrowthChart extends PureComponent {

	render() {  
	return (
			<Card>
            <CardHeader title= "Growth Analysis" />
            <CardContent>

                <div style={{display:'flex', width: '100%', margin:'3%'}}>
                    <img src={green} alt="" style={{height:120,marginLeft:'15%'}}/>
                    <h2 style={{textAlign:'right',marginLeft:'15%'}}>Total Growth in Order: {dataa[0].value}%</h2>
                </div>
                
                <div style={{display:'flex', width: '100%', margin:'2%'}}>
                    <img src={amber} alt="broke" style={{height:120,marginLeft:'15%'}}/>
                    <h2 style={{textAlign:'right',marginLeft:'15%'}}>Total Growth in Amount: {dataa[1].value}%</h2>
                </div>
                
                
            </CardContent>
        </Card>
		);
	}
}

// interface LabelType {
//   cx: number;
//   cy: number;
//   midAngle: number;
//   innerRadius: number;
//   outerRadius: number;
//   percent: number;
//   index: number;
// }
export const GrowthC = ({record}:any) => {

	dataa[0].value = record.orderGrowth;
	dataa[1].value = record.amountGrowth;
	console.log(record.onlinePercentage);

	return(<GrowthChart/>)
}