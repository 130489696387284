import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    BooleanField,
    BooleanInput,
    EditButton,
    TextInput,
    Edit,
    SimpleForm,
    Create,
    Filter,
    Pagination,
    UrlField,
    required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const AdPlacementPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ExpandShow = props => (
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="source" {...props}/>
            <TextInput label="Guids" source="guids" {...props}/>
            <TextInput source="textHeading" fullWidth />
            <TextInput source="textBody" fullWidth />
            <TextInput source="ctaText" fullWidth />
            <UrlField source="ctaUrl" fullWidth />
            <UrlField source="imageUrl" fullWidth />
            <TextInput source="couponCode" fullWidth />
            <BooleanInput source="isActive" validate={required()}/>
        </SimpleForm>
    </Edit>
);

export const AdPlacementList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List className={classes.root} filters={<AdPlacementFilter />} {...props}  perPage={50}  pagination={<AdPlacementPagination />}>
        <Datagrid expand={<ExpandShow />}>
            <TextField source="label" />
            <NumberField source="textHeading" />
            <TextField source="ctaText" />
            <BooleanField source="isActive" />
            <EditButton />
        </Datagrid>
    </List>
);
}

const AdPlacementFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const AdPlacementEdit = props => {
    const classes = useStyles(); 
    return(
    <Edit title={<AdPlacementTitle />} className={classes.root} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="source" {...props}/>
            <TextInput multiline label="Guids" source="guids" {...props}/>
            <TextInput source="textHeading" fullWidth />
            <TextInput source="textBody" fullWidth />
            <TextInput source="ctaText" fullWidth />
            <TextInput source="ctaUrl" fullWidth />
            <TextInput source="imageUrl" fullWidth />
            <TextInput source="couponCode" fullWidth />
            <BooleanInput source="isActive" validate={required()}/>
        </SimpleForm>
    </Edit>
    );
};

export const AdPlacementCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="source" {...props}/>
            <TextInput multiline label="Guids" source="guids" {...props}/>
            <TextInput source="textHeading" fullWidth />
            <TextInput source="textBody" fullWidth />
            <TextInput source="ctaText" fullWidth />
            <TextInput source="ctaUrl" fullWidth />
            <TextInput source="AdImageUrl" fullWidth />
            <TextInput source="couponCode" fullWidth />
            <BooleanInput source="isActive" validate={required()}/>
        </SimpleForm>
    </Create>
);

const AdPlacementTitle = ({ record }) => {
    return <span> Post {record ? `"${record.label}"` : ''} </span>;
};