import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Input,InputLabel } from "@material-ui/core";

// core components
// import HeaderLinks from "../components/Header/HeaderLinks.js";
// import Footer from "../components/Footer/Footer.js";
import GridContainer from "../components/loginPage/Grid/GridContainer.js";
import GridItem from "../components/loginPage/Grid/GridItem.js";
import Button from "../components/loginPage/CustomButtons/Button.js";
import Card from "../components/loginPage/Card/Card.js";
import CardBody from "../components/loginPage/Card/CardBody.js";
import CardHeader from "../components/loginPage/Card/CardHeader.js";
import CardFooter from "../components/loginPage/Card/CardFooter.js";
import logo from "../assets/img/logo.png";


import styles from "../assets/jss/loginPage.js";

import image from "../assets/img/bg.jpg";

const useStyles = makeStyles(styles);

const ZelishLogin = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setError] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
        setCardAnimation("");
      }, 700);
    const classes = useStyles();
    


    const submit = e => {
        e.preventDefault();
        login({ email, password }).catch(() =>
            setError('Invalid email or password')
        );
    };

    return (
      <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                  <div >
                    <img src={logo} alt="Logo" />
                  </div>
                    <h3>Zelish Admin Dashboard</h3>
                  </CardHeader>
                  <CardBody>

                    <FormControl className={classes.forminput}>
                      <InputLabel> Email</InputLabel>
                      <Input onChange={e => setEmail(e.target.value)}></Input> 
                    </FormControl>

                    <FormControl className={classes.forminput}>
                    <InputLabel> Password</InputLabel>
                      <Input onChange={e => setPassword(e.target.value)}></Input>
                    </FormControl>

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button onClick={submit} simple color="primary" size="lg">
                      LOGIN
                    </Button>
                  </CardFooter>
                  <div style={{textAlign:'center',color:'red'}}>
                    {loginError}
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
    );
}

export default ZelishLogin;