import * as React from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    NumberField, 
    EditButton, 
    TextInput,
    NumberInput,
    BooleanField, 
    BooleanInput, 
    Edit, 
    SimpleForm, 
    Create, 
    Filter, 
    ReferenceInput, 
    required, 
    SelectInput, 
    UrlField,
    Pagination
} from 'react-admin';

const StoryPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
export const StoryList = props => { 

    return (
    <List 
        component="div"  
        filters={<StoryFilter />} 
        perPage={50}
        pagination={<StoryPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <NumberField source="sequenceNo" />,
            <TextField source="storyType" />,
            <TextField label="Author name" source="author" />,
            <TextField source="title"/>
            <UrlField source="containerUrl" />,
            <UrlField source="imageUrl" />, 
            <TextField source="group"/>            
            <BooleanField source="isActive" />        
            <EditButton/>
        </Datagrid>
    </List>
    )
};

export const StoryEdit = props => (
    <Edit title={<StoryTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="title" />
            <TextInput source="containerUrl" />
            <TextInput source="imageUrl" />
            <TextInput source="additionalText" />
            <TextInput label="Author name" source="author" />
            <TextInput source="storyType" />
            <NumberInput source="sequenceNo" />
            <TextInput source="authorImage" />
            <TextInput source="group" />
            <BooleanInput source="isActive" validate={required()}/>            
        </SimpleForm>
    </Edit>
);

const StoryTitle = ({ record }) => {
    return <span> {record ? `"${record.title}"` : ''} </span>;
};

export const StoryCreate = props => (
    <Create {...props}>
        <SimpleForm> 
            <TextInput source="title" validate={required()}/>
            <TextInput source="containerUrl" />
            <TextInput source="imageUrl" validate={required()}/>
            <TextInput source="additionalText" />
            <TextInput label="Author name" source="author" />
            <TextInput source="storyType" validate={required()}/>
            <NumberInput source="sequenceNo" validate={required()}/>
            <TextInput source="authorImage" validate={required()}/>
            <TextInput source="group" />
            <BooleanInput source="isActive" validate={required()}/>            
        </SimpleForm>
    </Create>
);

const StoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Title" source="title" reference="Story" allowEmpty>
            <SelectInput optionText="title" />
        </ReferenceInput>
    </Filter>
);