import {useQuery, Loading } from "react-admin";
import * as React from "react";
import { CardContent,Card as MuiCard, withStyles  } from '@material-ui/core/';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import {  Title } from 'react-admin';
import LinkToRelatedRecipe from './LinkToRelatedRecipe';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

export const RecipeList = () => {
    
    const recipeWriter = localStorage.getItem('recipeWriterId');
    const classes = useStyles();
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: "RecipeInfo",
        payload: {filter: {'recipeWriterId':recipeWriter}}
    });

    if (loading) return <Loading />;
    if (error) return '';
    if (!data) return null;
    
    console.log(data)
    return(
        
        <Card className={classes.root} >
            <Title
                title="Recipe"
            />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            id
                        </TableCell>
                        <TableCell>
                            recipeName
                        </TableCell>
                        <TableCell>
                            recipeUrl
                        </TableCell>
                        <TableCell>
                            totalNoOfVisits
                        </TableCell>
                        <TableCell>
                            totalNoOfOrders
                        </TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(segment => (
                        <TableRow key={segment.id} >
                            <TableCell>
                                <LinkToRelatedRecipe segment={segment} />
                            </TableCell>
                            <TableCell>
                                {segment.recipeName}
                            </TableCell>
                            <TableCell>
                                {segment.recipeUrl}
                            </TableCell>
                            <TableCell>
                                {segment.totalNoOfVisits}
                            </TableCell>
                            <TableCell>
                                {segment.totalNoOfOrders}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}