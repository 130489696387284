import * as React from 'react';
import { FC } from 'react';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
    value2?: number;
}

const MonthlySummary: FC<Props> = ({ value, value2 }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/Order"
            icon={CalendarTodayIcon}
            title={translate('Monthly Summary')}
            subtitle={"Total Order : "+value?.toString()}
            subtitle_2={"Total Amount : "+value2?.toString()}
        />
    );
};

export default MonthlySummary;
