import * as React from 'react';
import { Component } from 'react';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import { Admin, Resource} from 'react-admin';
import customRoutes from './routes/customRoutes'

import {StoryList, StoryEdit, StoryCreate} from './components/stories/Stories';
import {AdPlacementList, AdPlacementEdit, AdPlacementCreate} from './components/AdPlacement/AdPlacement';
import {SectionList, SectionEdit, SectionCreate} from './components/section/Section';
import {NutrientList, NutrientEdit, NutrientCreate} from './components/nutrient/Nutrient';
import {TaggingList, TaggingEdit, TaggingCreate} from './components/tagging/Tagging';
import {ImageUploadList, ImageUploadEdit, ImageUploadCreate} from './components/imageUpload/ImageUpload';
import {SurveyList, SurveyEdit, SurveyCreate} from './components/survey/Survey';
import {DealsList, DealsEdit, DealsCreate} from './components/deals/Deals';
import {AuthorList, AuthorEdit, AuthorCreate} from './components/author/Author';
import {IngList, IngEdit, IngCreate} from './components/ingredient/Ingredient';
//import {CouponList} from './components/coupon/Coupon';

//import {OrderList} from './components/order/Order';
//import {OrderListVendor} from './components/order/OrderVendor';
//import OrderEdit from './components/order/OrderEdit';

import authProvider from './view_and_auth/AuthProviderCustom'
import ZelishLogin from './view_and_auth/ZelishLogin';

import {Dashboard} from './dashboard';

import {Layout} from './view_and_auth/layout'

//import {VendorList} from './components/vendor/VendorList'
//import {VendorEdit} from './components/vendor/VendorEdit'
//import {VendorAllOrder} from './components/vendor/VendorAllOrders';

//import {RecipeAllOrder} from './components/recipe/RecipeAllOrder'
//import {RecipeInternal} from './components/recipe/RecipeInternal'

class App extends Component {
    constructor() {
        super();
        this.state = { dataProvider: null };
    }

    componentDidMount() {
        buildGraphQLProvider({ 
            clientOptions: { 
                uri: process.env.REACT_APP_BASE_URL+'graphql' }
            })
            .then( dataProvider => this.setState({ dataProvider }))
            .catch(err=>{console.error(err)});
    }


    render() {
        const { dataProvider } = this.state;


        if (!dataProvider) {
            return <div>Loading</div>;
        }
        const userper = localStorage.getItem('permissions')
        return (
            <Admin 
            // theme={theme} 
            dataProvider={dataProvider} 
            loginPage={ZelishLogin} 
            authProvider={authProvider}
            layout={Layout}
            dashboard={ userper === 'VENDOR_ADMIN' ? Dashboard : null }
            // dashboard = { Dashboard }
            customRoutes={customRoutes}>

            {permissions => [   

                /*
                permissions === 'SUPER_ADMIN'
                ?<Resource name="Order" list={OrderList} edit={OrderEdit}/>:null,

                permissions === 'VENDOR_ADMIN'
                ?<Resource name="Order" list={OrderListVendor} edit={OrderEdit}/>:null,
                */

                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="Section" list={SectionList} edit={SectionEdit} create={SectionCreate}/>:null,

                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="AdPlacement" list={AdPlacementList} edit={AdPlacementEdit} create={AdPlacementCreate}/>:null,

                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="Nutrient" list={NutrientList} edit={NutrientEdit} create={NutrientCreate}/>:null,
                
                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="Tagging" list={TaggingList} edit={TaggingEdit} create={TaggingCreate}/>:null,

                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="ImageUpload" list={ImageUploadList} edit={ImageUploadEdit} create={ImageUploadCreate}/>:null,
                
                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN'
                ?<Resource name="Survey" list={SurveyList} edit={SurveyEdit} create={SurveyCreate}/>:null,

                permissions === 'INTERNAL_ADMIN' || permissions === 'SUPER_ADMIN' ||  permissions == 'QA_ADMIN'
                ?<Resource name="Deals" list={DealsList} edit={DealsEdit} create={DealsCreate}/>:null,

                permissions === 'INTERNAL_ADMIN'|| permissions === 'SUPER_ADMIN'
                ?<Resource name="Story" list={StoryList} edit={StoryEdit} create={StoryCreate}/>:null,
                
                permissions === 'INTERNAL_ADMIN'|| permissions === 'SUPER_ADMIN'
                ?<Resource name="Author" list={AuthorList} edit={AuthorEdit} create={AuthorCreate}/>:null,

                permissions === 'INTERNAL_ADMIN'|| permissions === 'SUPER_ADMIN'
                ?<Resource options={{ label: 'Ingredients' }} name="Ing" list={IngList} edit={IngEdit} create={IngCreate}/>:null,

                /*
                permissions === 'SUPER_ADMIN'
                ?<Resource name="Coupon" list={CouponList}/>:null,

                permissions === 'OPS_ADMIN'
                ?<Resource  name="VendorInfo" edit={VendorEdit} options={{label:"Vendor"}} list={VendorList}/>:null,

                permissions === 'OPS_ADMIN'
                ?<Resource  name="Order" edit={OrderEdit} options={{label:"Vendor All Pending"}} list={VendorAllOrder}/>:null,

                permissions === 'RECIPE_ADMIN' || permissions === 'OPS_ADMIN'
                ?<Resource  name="RecipeOrdersInfo" options={{label:"Recipe Order"}} list={RecipeAllOrder}/>:null,

                permissions === 'OPS_ADMIN'
                ?<Resource  name="InternalRecipeInfo" options={{label:"All Recipe Info "}} list={RecipeInternal}/>:null,
                */

            ]}

            </Admin>
        );
    }
}

export default App;