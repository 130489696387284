import * as React from 'react';
import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';


const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedRecipe: FC<{ segment: any }> = ({ segment }) => {
    const classes = useStyles();
    let recipe = segment.recipeUrl
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/RecipeOrdersInfo',
                search: stringify({
                    filter: JSON.stringify({ recipeURL: recipe}),
                }),
            }}
            className={classes.link}
        >
        {segment.id}
        </Button>
    );
};

export default LinkToRelatedRecipe;
