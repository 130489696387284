import * as React from 'react';
import { FC } from 'react';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import CardWithIcon from './CardWithIcon';

interface Props {
    valueordr?: number;
    valueamt?: number;
}

const DailySummary: FC<Props> = ({valueordr,valueamt}) => {
    const valueFirst = valueordr?.toString()
    const valueSecond = valueamt?.toString()


    return (
        <CardWithIcon
            to = "/Order"
            icon={AvTimerIcon}
            title='Daily Summary'
            subtitle= {"Total Order : "+valueFirst}
            subtitle_2 = {"Total Amount : "+valueSecond}
        />
    );
};

export default DailySummary;
