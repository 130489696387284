const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
});

export const getImageData = async(params) => {
    if (params.pictures.rawFile){
        // console.log('params.pictures', params.pictures.rawFile);
        const image = params.pictures.rawFile;
        let base64 = await convertFileToBase64(image);
        return base64
    }  
};