import {Box, CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {Filter, FilterList, FilterListItem, TextInput} from "react-admin";
import * as React from "react";

export const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '1500000em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <SectionFilter />
                <PlatformFilter />
                <IsActiveFilter />
                <SectionTypeFilter />
            </CardContent>
        </Card>
    </Box>
);

const SectionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const PlatformFilter = () => (
    <FilterList
        label="Platform"
    >
        <FilterListItem
            label="Alexa"
            value={{ platform: "Alexa" }}
        />
        <FilterListItem
            label="GoogleHome"
            value={{ platform: "GoogleHome" }}
        />
        <FilterListItem
            label="WebApp"
            value={{ platform: "WebApp" }}
        />
        <FilterListItem
            label="AndroidApp"
            value={{ platform: "AndroidApp" }}
        />
        <FilterListItem
            label="IosApp"
            value={{ platform: "IosApp" }}
        />
    </FilterList>
)

const IsActiveFilter = () => (
    <FilterList
        label="Active"
    >
        <FilterListItem
            label="True"
            value={{ isActive: true }}
        />
        <FilterListItem
            label="False"
            value={{ isActive: false }}
        />
    </FilterList>
);

const SectionTypeFilter = (props) => (
    <FilterList
        label="SectionType"
    >
        <FilterListItem
            label="COLLECTION"
            value={{ sectionType: "COLLECTION" }}
        />
        <FilterListItem
            label="STORY"
            value={{ sectionType: "STORY" }}
        />
    </FilterList>
);