import { Datagrid, List, TextField, FilterLiveSearch, Filter, TextInput } from "react-admin";
import * as React from "react";
import { CardContent,Card as MuiCard, withStyles  } from '@material-ui/core/';

// const ListVendorPending = ({record}) => {
//     console.log(record.id)
// }

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '19em',
            height: 250,
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);





const VendorFilter = (props) => (
    <Filter {...props}>
        <FilterLiveSearch label="Vender Search" source="vendorName" alwaysOn />
        <TextInput label="Vender Phone NUmber" source="vendorContactNumber" />
    </Filter>
);

export const VendorList = props => (
    <List filters={<VendorFilter/>} filterDefaultValues={{'orderDeliveryStatus':"PENDING_DELIVERY"}} {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="vendorName" />
            <TextField label="Vendor Contact Number" source="phoneNo" />
            <TextField source="pendingDeliveries" />
        </Datagrid>
    </List>
);

