import * as React from "react";
import { Route } from 'react-router-dom';
import Resetpasword from '../view_and_auth/ResetPassoword';
import {VendorList} from '../components/vendor/VendorList';
import {VendorAllOrder} from '../components/vendor/VendorAllOrders';
import DashboardInternalVendor from '../dashboard_internal_vender/DashboardInternalVendor';
import DashboardRecipeWriter from '../dashboard_recipe/DashboardRecipeWriter';
import {RecipeList} from '../components/recipe/RecipeList';


export default [
    <Route exact path="/resetpassword" component={Resetpasword} />,
    <Route exact path="/vendorList" component={VendorList} />,
    <Route exact path="/vendorAllOrder" component={VendorAllOrder} />,
    <Route exact path="/paymentSummary" component={DashboardInternalVendor} />,
    <Route exact path="/recipe" component={RecipeList} />,
    <Route exact path="/recipeSummary" component={DashboardRecipeWriter} />,
];