import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    BooleanField,
    BooleanInput,
    EditButton,
    TextInput,
    CheckboxGroupInput,
    NumberInput,
    Edit,
    SimpleForm,
    Create,
    Pagination,
    required,
    regex,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { FilterSidebar } from './FilterSidebar';

const pageNames = [
    { id: 'home', name: 'home' },
    { id: 'search', name: 'search' },
    { id: 'WTCIdeas', name: 'WTCIdeas' },
];

const countryType = [
    { id: 'IN', name: 'India' },
    { id: 'US', name: 'USA' },
    { id: 'CA', name: 'Canada' },
    { id: 'GB', name: 'Great Britain' },
    { id: 'AU', name: 'Australia' },
    { id: 'IT', name: 'Italy' },

];

const dietTypes = [
    { id: 'VEGAN', name: 'Vegan' },
    { id: 'VEGETARIAN', name: 'Vegetarian' },
    { id: 'NONVEGETARIAN', name: 'I eat everything' },
]

const platform = [
    { id: 'Alexa', name: 'Alexa' },
    { id: 'GoogleHome', name: 'GoogleHome' },
    { id: 'WebApp', name: 'WebApp' },
    { id: 'AndroidApp', name: 'AndroidApp' },
    { id: 'IosApp', name: 'IosApp' }
];

const platformVendor = [
    { id: 'KloveChef', name: 'KloveChef' },
    { id: 'NutriPot', name: 'NutriPot' },
    { id: 'NutriBlend', name: 'NutriBlend' },
    { id: 'SanjeevKapoor', name: 'SanjeevKapoor' },
    { id: 'TataSampann', name: 'TataSampann' },
    { id: 'ckbk', name: 'ckbk' },
    { id: 'RobertRose', name: 'RobertRose' },
    { id: 'Sottilette', name: 'Sottilette' },
    { id: 'KanaksKitchen', name: 'KanaksKitchen' }
];

const platformLanguage = [
    { id: 'hi', name: 'hi' },
    { id: 'en', name: 'en' }
];

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const SectionPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 300]} {...props} />;
export const SectionList = ({permissions,...props}) => {
    const classes = useStyles();
    
        return(
        <List
            aside={<FilterSidebar />}
            className={classes.root}
            perPage={50}
            pagination={<SectionPagination />}
            sort={{ field: "sequenceNo", order: "ASC" }}
            {...props}
        >
            <Datagrid expand={<ExpandShow />}>
                <TextField source="label" />
                <NumberField source="sequenceNo" sortByOrder="ASC" />
                <TextField source="sectionType" />
                <BooleanField source="hasCuisineFilter" />
                <BooleanField source="isActive" sortByOrder="ASC" />
                <TextField label="source" source="filterQuery.source" />
                <EditButton />
            </Datagrid>
        </List>
    );
    }

const ExpandShow = props => (
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="labelSynonyms" fullWidth />
            <TextInput source="relativeUrl" validate={validateRelativeUrl} fullWidth/>
            <NumberInput source="sequenceNo" validate={validateSequenceNumber} label="order No"/>
            <TextInput source="sectionType" validate={required()}/>
            <TextInput source="cardName" />
            <TextInput source="imageUrl" fullWidth/>
            <TextInput source="demoUrl" fullWidth/>
            <BooleanInput source="hasCuisineFilter" validate={required()}/>
            <BooleanInput source="isActive" validate={required()}/>
            <BooleanInput source="isProCollection" />
            <BooleanInput source="isHindiCollection" />
            <CheckboxGroupInput label="page" source="page" choices={pageNames} validate={required()} {...props}/>
            <CheckboxGroupInput label="DietTypes" source="dietTypesList" choices={dietTypes} {...props}/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" choices={platform} {...props}/>
            <CheckboxGroupInput label="PlatformVendors" source="platformVendorList" choices={platformVendor} {...props}/>
            <CheckboxGroupInput label="PlatformLanguages" source="platformLanguageList" choices={platformLanguage} {...props}/>
            <TextInput source="speech" fullWidth/>
            <TextInput source="rePromptSpeech" fullWidth/>
            <TextInput label="dishTags" source="filterQuery.dishTags" validate={validateDishTag} {...props}/>
            <TextInput label="source" source="filterQuery.source" {...props}/>
        </SimpleForm>
    </Edit>
);


export const SectionEdit = props => {
    const classes = useStyles(); 
    return(
    <Edit title={<SectionTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="labelSynonyms" fullWidth />
            <TextInput source="relativeUrl" validate={validateRelativeUrl} fullWidth/>
            <NumberInput source="sequenceNo" validate={validateSequenceNumber} label="order No"/>
            <TextInput source="sectionType" validate={required()}/>
            <TextInput source="cardName" />
            <TextInput multiline label="Search Tags" source="searchTags" {...props}/>
            <TextInput source="imageUrl" fullWidth/>
            <TextInput source="demoUrl" fullWidth/>
            <BooleanInput source="hasCuisineFilter" validate={required()}/>
            <BooleanInput source="isActive" validate={required()}/>
            <BooleanInput source="isProCollection" />
            <BooleanInput source="isHindiCollection" />
            <CheckboxGroupInput label="page" source="page" choices={pageNames} validate={required()} {...props}/>
            <CheckboxGroupInput label="DietTypes" source="dietTypesList" choices={dietTypes} {...props}/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" choices={platform} {...props}/>
            <CheckboxGroupInput label="PlatformVendors" source="platformVendorList" choices={platformVendor} {...props}/>
            <CheckboxGroupInput label="PlatformLanguages" source="platformLanguageList" choices={platformLanguage} {...props}/>
            <TextInput source="speech" fullWidth/>
            <TextInput source="rePromptSpeech" fullWidth/>
            <TextInput label="dishTags" source="filterQuery.dishTags" validate={validateDishTag} {...props}/>
            <TextInput label="source" source="filterQuery.source" {...props}/>
            <TextInput label="skillLevel" source="filterQuery.skillLevel" {...props}/>
            <TextInput label="mealTypes" source="filterQuery.mealTypes" {...props}/>
            <TextInput label="ingredient" source="filterQuery.ingredient" {...props}/>
            <TextInput label="recipeWord" source="filterQuery.recipeWord" {...props}/>
            <TextInput label="dishType" source="filterQuery.dishType" {...props}/>
            <TextInput label="occasions" source="filterQuery.occasions" {...props}/>
            <TextInput label="holidays" source="filterQuery.holidays" {...props}/>
            <TextInput label="beverages" source="filterQuery.beverages" {...props}/>
            <TextInput label="categories" source="filterQuery.categories" {...props}/>
            <TextInput label="specialCategories" source="filterQuery.specialCategories" {...props}/>
            <TextInput label="miscTags" source="filterQuery.miscTags" {...props}/>
            <TextInput label="cuisineTypes" source="filterQuery.cuisineTypes" {...props}/>
            <TextInput label="diets" source="filterQuery.diets" {...props}/>
            <TextInput label="descriptors" source="filterQuery.descriptors" {...props}/>
            <TextInput label="keywords" source="filterQuery.keywords" {...props}/>
            <TextInput label="seasons" source="filterQuery.seasons" {...props}/>
            <TextInput label="cookingTechniques" source="filterQuery.cookingTechniques" {...props}/>
            <TextInput label="includedGuids" source="filterQuery.includedGuids" {...props}/>
            <TextInput label="excludedGuids" source="filterQuery.excludedGuids" {...props}/>
            <TextInput multiline label="Ranked Recipe Guids" source="rankedGuids" {...props}/>
        </SimpleForm>
    </Edit>
    );
};

export const SectionCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="label" validate={required()} fullWidth />
            <TextInput source="labelSynonyms" fullWidth />
            <TextInput source="relativeUrl" validate={validateRelativeUrl} fullWidth/>
            <NumberInput source="sequenceNo" validate={validateSequenceNumber} label="order No"/>
            <TextInput source="sectionType" validate={required()}/> 
            <TextInput source="cardName" />
            <TextInput multiline label="Search Tags" source="searchTags" {...props}/>
            <TextInput source="imageUrl" fullWidth/>
            <BooleanInput source="hasCuisineFilter" validate={required()}/>
            <BooleanInput source="isActive" validate={required()}/>
            <BooleanInput source="isProCollection" />
            <BooleanInput source="isHindiCollection" />
            <CheckboxGroupInput label="page" source="page" choices={pageNames} validate={required()} {...props}/>
            <CheckboxGroupInput label="DietTypes" source="dietTypesList" choices={dietTypes} defaultValue={["VEGETARIAN", "VEGAN", "NONVEGETARIAN"]} {...props}/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} defaultValue={["IN", "US", "CA", "GB", "AU", "IT"]} />
            <CheckboxGroupInput label="Platforms" source="platformList" choices={platform} {...props}/>
            <CheckboxGroupInput label="PlatformVendors" source="platformVendorList" choices={platformVendor} {...props}/>
            <CheckboxGroupInput label="PlatformLanguages" source="platformLanguageList" choices={platformLanguage} {...props}/>
            <TextInput source="speech" fullWidth/>
            <TextInput source="rePromptSpeech" fullWidth/>
            <TextInput label="dishTags" source="filterQuery.dishTags" validate={validateDishTag} {...props}/>
            <TextInput label="source" source="filterQuery.source" {...props}/>
            <TextInput label="skillLevel" source="filterQuery.skillLevel" {...props}/>
            <TextInput label="mealTypes" source="filterQuery.mealTypes" {...props}/>
            <TextInput label="ingredient" source="filterQuery.ingredient" {...props}/>
            <TextInput label="recipeWord" source="filterQuery.recipeWord" {...props}/>
            <TextInput label="dishType" source="filterQuery.dishType" {...props}/>
            <TextInput label="occasions" source="filterQuery.occasions" {...props}/>
            <TextInput label="holidays" source="filterQuery.holidays" {...props}/>
            <TextInput label="beverages" source="filterQuery.beverages" {...props}/>
            <TextInput label="categories" source="filterQuery.categories" {...props}/>
            <TextInput label="specialCategories" source="filterQuery.specialCategories" {...props}/>
            <TextInput label="miscTags" source="filterQuery.miscTags" {...props}/>            
            <TextInput label="cuisineTypes" source="filterQuery.cuisineTypes" {...props}/>
            <TextInput label="diets" source="filterQuery.diets" {...props}/>
            <TextInput label="descriptors" source="filterQuery.descriptors" {...props}/>
            <TextInput label="keywords" source="filterQuery.keywords" {...props}/>
            <TextInput label="seasons" source="filterQuery.seasons" {...props}/>
            <TextInput label="cookingTechniques" source="filterQuery.cookingTechniques" {...props}/>
            <TextInput label="includedGuids" source="filterQuery.includedGuids" {...props}/>
            <TextInput label="excludedGuids" source="filterQuery.excludedGuids" {...props}/>
            <TextInput multiline label="Ranked Recipe Guids" source="rankedGuids" {...props}/>
        </SimpleForm>
    </Create>
);

const SectionTitle = ({ record }) => {
    return <span> Post {record ? `"${record.label}"` : ''} </span>;
};

const validateRelativeUrl = regex(/^\S*$/, 'Must not contain spaces');
const validateDishTag = (value, allValues) => {    
    if (value && !value.match(/^\S*$/)) {            
        return 'Must not contain spaces';
    }
    return [];
};

const validateSequenceNumber = (value, allValues) => {    
    if (value && value < -39) {
        return 'Number cannot go below -39';
    }
    else if (value && value > 999) {
        return 'Number cannot go above 999';
    }
    else if (!value){
        return 'Cannot be Blank';
    }
    return [];
};