import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    Edit,
    NumberInput,
    NumberField,
    SimpleForm,
    Create,
    Pagination,
    required,
    regex, ArrayInput, SimpleFormIterator, CheckboxGroupInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const dietTypes = [
    { id: 'VEGAN', name: 'Vegan' },
    { id: 'VEGETARIAN', name: 'Vegetarian' },
    { id: 'NONVEGETARIAN', name: 'I eat everything' },
]

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const NutrientPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const NutrientList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List
        className={classes.root}
        perPage={50}
        pagination={<NutrientPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="label" />
            <NumberField source="sequenceNo" sortByOrder="ASC" />
            <EditButton />
        </Datagrid>
    </List>
);
}

const validateRelativeUrl = regex(/^\S*$/, 'Must not contain spaces');

export const NutrientEdit = props => {
    return(
    <Edit title={<NutrientTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth {...props}/>
            <TextInput label="Tag Line" source="tagLine" fullWidth {...props}/>
            <TextInput label="Image URL" source="imageURL" validate={validateRelativeUrl} fullWidth {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <ArrayInput source="nutrientIngredients">
                <SimpleFormIterator>
                    <TextInput source="label" validate={required()} fullWidth {...props}/>
                    <TextInput source="imgUrl" validate={validateRelativeUrl} fullWidth {...props}/>
                    <CheckboxGroupInput label="DietTypes" source="dietTypesList" choices={dietTypes} {...props}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
    );
};

export const NutrientCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="label" validate={required()} fullWidth {...props}/>
            <TextInput label="Tag Line" source="tagLine" fullWidth {...props}/>
            <TextInput label="Image URL" source="imageURL" validate={validateRelativeUrl} fullWidth {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <ArrayInput source="nutrientIngredients">
                <SimpleFormIterator>
                    <TextInput source="label" validate={required()} fullWidth {...props}/>
                    <TextInput source="imgUrl" validate={validateRelativeUrl} fullWidth {...props}/>
                    <CheckboxGroupInput label="DietTypes" source="dietTypesList" choices={dietTypes} {...props}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

const NutrientTitle = ({ record }) => {
    return <span> Post {record ? `"${record.label}"` : ''} </span>;
};