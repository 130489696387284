import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    Edit,
    SimpleForm,
    Create,
    Pagination,
    required,
    SelectInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { FilterSidebar, tag_choices } from './FilterSidebar';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const TaggingPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const TaggingList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List
        aside={<FilterSidebar />}
        className={classes.root}
        perPage={50}
        pagination={<TaggingPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="tag"/>
            <TextField source="tag_value"/>
            <EditButton/>
        </Datagrid>
    </List>
);
}


export const TaggingCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="tag" validate={required()} choices={tag_choices} {...props}/>
            <TextInput source="tag_value" validate={required()} {...props}/>
            <TextInput multiline label="Guids - To add tag" source="guids_tag" {...props}/>
            <TextInput multiline label="Guids - To remove tag" source="guids_remove" {...props}/>
        </SimpleForm>
    </Create>
);

export const TaggingEdit = props => {
    return(
    <Edit title={<TaggingTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <SelectInput disabled source="tag" validate={required()} choices={tag_choices} {...props}/>
            <TextInput disabled source="tag_value" validate={required()} {...props}/>
            <TextInput multiline label="Guids - To add tag" source="guids_tag" {...props}/>
            <TextInput multiline label="Guids - To remove tag" source="guids_remove" {...props}/>
        </SimpleForm>
    </Edit>
    );
};

const TaggingTitle = ({ record }) => {
    return <span> Post {record ? `"${record.tag}"` : ''} </span>;
};