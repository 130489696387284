import * as React from 'react';
// import { useState, useEffect } from 'react';
import { Datagrid, List, TextField, NumberField, Filter, TextInput, FilterList, FilterListItem, DateInput } from 'react-admin';
import { CardContent,Card as MuiCard, withStyles  } from '@material-ui/core/';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';


const VendorFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Order ID" source="zelishOrderId" alwaysOn />
        <TextInput label="Customer Contact #" inputProps={{ maxLength: 10 }} source="customerContactNumber" />

        <DateInput label="Order Date From" inputProps={{ max:"2999-12-31" }}  source="orderCreationStartDate" alwaysOn />
        <DateInput label="Order Date to" inputProps={{ max:"2999-12-31" }} source="orderCreationEndDate" alwaysOn />
    </Filter>
);

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '19em',
            height: 'auto',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

//Filter Section for Payment Method
const PaymentMode = () => (
    <FilterList
        label="PAYMENT MODE"
        icon={<MonetizationOnIcon/>}
    >
        <FilterListItem
            label="CARD"
            value={{
                paymentMode: "CARD"
            }}
        />
        <FilterListItem
            label="COD"
            value={{
                paymentMode:"COD"
            }}
        />
        <FilterListItem
            label="CASHFREE"
            value={{
                paymentMode:"CASHFREE"
            }}
        />
    </FilterList>
);

//Filter Section for Payment Method
const OrderStatus = () => (
    <FilterList
        label="ORDER STATUS"
        icon={<MonetizationOnIcon/>}
    >
        <FilterListItem
            label="NEW ORDER"
            value={{
                orderDeliveryStatus: "NEW_ORDER"
            }}
        />
        <FilterListItem
            label="PENDING DELIVERY"
            value={{
                orderDeliveryStatus: "PENDING_DELIVERY"
            }}
            
        />
        
        <FilterListItem
            label="ACCEPTED"
            value={{
                orderDeliveryStatus:"ACCEPTED"
            }}
        />
        <FilterListItem
            label="COMPLETED"
            value={{
                orderDeliveryStatus:"COMPLETED"
            }}
        />
        <FilterListItem
            label="CANCELLED"
            value={{
                orderDeliveryStatus:"CANCELLED"
            }}
        />
        <FilterListItem
            label="REFUNDED"
            value={{
                orderDeliveryStatus:"REFUNDED"
            }}
        />
    </FilterList>
);


const TimeMode = () => (
    <FilterList
    label="ORDER BY TIME" icon={<AccessTimeIcon />}
    >
        <FilterListItem
            label="Last week"
            value={{
                orderCreationStartDate: subWeeks(startOfWeek(new Date()), 1).toISOString(),
                orderCreationEndDate: startOfWeek(new Date()).toISOString(),
            }}
        />
    </FilterList>
);

//Filter component are placed inside FilterSidebar component
const FilterSidebar = () => (
    <Card>
        <CardContent>
            {/* <FilterLiveSearch source="zelishOrderId" /> */}
            <OrderStatus />
            <PaymentMode />
            <TimeMode />
        </CardContent>
    </Card>
);

export const VendorAllOrder = () => {
    let fakeProps = {
        basePath: "/Order",
        hasCreate: false,
        hasEdit: true,
        hasList: true,
        hasShow: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "Order"
    }
    // const pending_filter = "PENDING_DELIVERY"
    return(
    <List aside={<FilterSidebar/>} filters={<VendorFilter/>} filterDefaultValues={{'orderDeliveryStatus':"PENDING_DELIVERY"}} {...fakeProps} >
        <Datagrid rowClick="edit" >
            <TextField label="Zelish Order ID" source="zelishOrderId" />
            <TextField label="Order Date" source="createdAt" />
            <TextField label="Delivery Date" source="selectedDate" />
            <TextField label="Delivery Time" source="selectedDeliverySlots.fromTime" />
            <TextField label="Order Status" source="orderDeliveryStatus" />
            <TextField label="Payment Status" source="orderPaymentStatus" />
            <NumberField label="Total Amount" source="grandTotal" />
            <NumberField label="Product Count" source="itemsCount" />
            <TextField label="Payment Method" source="paymentMode" />
            <TextField label="Address" source="checkoutAddress" />
            <TextField label="Customer Contact" source="checkoutPhone" />
            <NumberField label="Refund Amount" source="refundAmount" />
            <TextField label="Comment" source="comments" />
        </Datagrid>
    </List>
    );
};