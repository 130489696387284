import React, {
    CSSProperties, useState
} from 'react';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import { useForm } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import {useQuery, Loading, AutocompleteInput, ReferenceInput, SimpleForm} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import green from '../assets/img/dashboard/Green_U.png'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {OrderC} from './OrderChart';
// import {GrowthC} from './GrowthChart';

// import TotalSummary from './TotalSummary';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: '2%',
        padding:'5%',
        width: '100%',
        height: 'auto',
      },
    },
    orderData:{
        marginLeft: '1%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '5%',
        marginTop:'5%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
  }));


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;
const options = ['Option 1', 'Option 2'];


const DashboardInternalVendor = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const classes = useStyles();
    // const vendor_id = localStorage.getItem('vendorId')
    // const [value, setValue] = useState(options[0]);
    // const [inputValue, setInputValue] = useState('');
    const [vendor, setVendor] = useState('V003');

    const handleChange = (event:any) => {
        setVendor(event.target.value);
      };

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: "InternalVendorReport",
        payload: {id:vendor}
    });

    if (loading) return <Loading />;
    if (error) return '';
    if (!data) return null;
    
    console.log("Data is fetched:");
    console.log(data);
    console.log(data.id);
    console.log(data.monthlyReport);
    
    const monthlyChartPaid = data.monthlyReport.paidPercentage.toString();
    const monthlyChartNotPaid = data.monthlyReport.notPaidPercentage.toString();
    const weeklyChartPaid = data.weeklyReport.paidPercentage.toString();
    const weeklyChartNotPaid = data.weeklyReport.notPaidPercentage.toString();
    // console.log(data.dailySummary.totalOrders);
    

    
    // return (<TotalSummary value={data.totalOrders} />)

    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                
                {/* <Welcome />
                
                <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount}/>
                <VerticalSpacer />
                <WeeklySummary value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}  />
                <VerticalSpacer />
                <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                <VerticalSpacer />
                <TotalSummary value={data.totalOrders} value2={data.totalAmount}/> */}
                
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                {/* <Welcome /> */}
            </div>
            <div style={styles.flex}>
                <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                    LAST WEEK
                </Typography>
                <Spacer />

                {/* <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount}/>
                <Spacer />
                <WeeklySummary value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}/>
                <Spacer />
                <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                <Spacer />
                <TotalSummary value={data.totalOrders} value2={data.totalAmount}/> */}
            </div>
            <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Total number of Orders : {data.weeklyReport.totalNumberOfOrders}</p>
                                <p>Total number of Pending Orders : {data.weeklyReport.totalNumberOfPendingOrders}</p>
                                <p>Total number of Completed Orders : {data.weeklyReport.totalNumberOfCompletedOrders}</p>
                                <p>Total number of Cancelled Orders : {data.weeklyReport.totalNumberOfCancelledOrders}</p>
                            </div>
                        </Paper>
            </div>
            <div style={styles.singleCol}>
                <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                    <h3 style={{display:'inline'}}>GROWTH IN ORDERS</h3>
                    <img src={green} alt="" style={{height:'5rem'}}/>
                    <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                        {data.weeklyReport.growthPercentage}%
                    </Typography>
                </Paper>
                {/* <PaymentC record={data}/> */}
            </div>
            <div style={styles.singleCol}>
                <Paper style={{margin:'2%'}} elevation={2} >
                <OrderC paid={weeklyChartPaid} notPaid={weeklyChartNotPaid}/>
                </Paper>
                {/* <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                /> */}
                {/* <GrowthC record={data}/> */}
            </div>


            <div style={styles.flex}>
                <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                    LAST MONTH
                </Typography>
                <Spacer />

                {/* <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount}/>
                <Spacer />
                <WeeklySummary value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}/>
                <Spacer />
                <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                <Spacer />
                <TotalSummary value={data.totalOrders} value2={data.totalAmount}/> */}
            </div>
            <div className={classes.root}>
                <Paper elevation={2} >
                    <div className={classes.orderData}>
                        <p>Total number of Orders : {data.monthlyReport.totalNumberOfOrders}</p>
                        <p>Total number of Pending Orders : {data.monthlyReport.totalNumberOfPendingOrders}</p>
                        <p>Total number of Completed Orders : {data.monthlyReport.totalNumberOfCompletedOrders}</p>
                        <p>Total number of Cancelled Orders : {data.monthlyReport.totalNumberOfCancelledOrders}</p>
                    </div>
                </Paper>
            </div>
            <div style={styles.singleCol}>
                <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                    <h3 style={{display:'inline'}}>GROWTH IN ORDERS</h3>
                    <img src={green} alt="" style={{height:'5rem'}}/>
                    <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                        {data.monthlyReport.growthPercentage}%
                    </Typography>
                </Paper>
                {/* <PaymentC record={data}/> */}
            </div>
            <div style={styles.singleCol}>
                <Paper style={{margin:'2%'}} elevation={2} >
                <OrderC paid={monthlyChartPaid} notPaid={monthlyChartNotPaid}/>
                </Paper>
            </div>
        </div>
    ) : (
        <>
            {/* <Welcome /> */}
            <div style={styles.flex}>
                {/* <SimpleForm style={{marginTop:'4%'}}>
                <ReferenceInput label="Vendor" source="vendorId" reference="Order">
                    <AutocompleteInput optionText="vendorId" />
                </ReferenceInput>
                </SimpleForm> */}


                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={vendor}
                        onChange={handleChange}
                    >
                        <MenuItem value={"V003"}>V003</MenuItem>
                        <MenuItem value={"V004"}>V004</MenuItem>
                        <MenuItem value={"V005"}>V005</MenuItem>
                        <MenuItem value={"V006"}>V006</MenuItem>
                        <MenuItem value={"V007"}>V007</MenuItem>
                    </Select>
                </FormControl>
            
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            LAST WEEK
                        </Typography>
                        <Spacer />
                         
                    </div>
                    <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Total number of Orders : {data.weeklyReport.totalNumberOfOrders}</p>
                                <p>Total number of Pending Orders : {data.weeklyReport.totalNumberOfPendingOrders}</p>
                                <p>Total number of Completed Orders : {data.weeklyReport.totalNumberOfCompletedOrders}</p>
                                <p>Total number of Cancelled Orders : {data.weeklyReport.totalNumberOfCancelledOrders}</p>
                            </div>
                        </Paper>
                    </div> 
                    <div style={styles.singleCol}>
                        
                        <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                            <h3 style={{display:'inline'}}>GROWTH IN ORDERS</h3>
                            <img src={green} alt="" style={{height:'5rem'}}/>
                            <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                                {data.weeklyReport.growthPercentage}%
                            </Typography>
                        </Paper>
                        {/* <div className={classes.root} style={{flexDirection:'row'}}>
                            <Paper elevation={2} >
                                <h3 style={{display:'inline'}}>GROWTH IN ORDERS</h3>

                                <img src={green} alt="" style={{height:'20%',marginLeft:'15%'}}/>
                                <Typography variant="h4" gutterBottom style={{width:'100%',textAlign:'center',color:'green',margin:'10%',display:'inline'}}>
                                    2%
                                </Typography>
                            </Paper>
                        </div> */}
                    </div>
                    <div style={styles.singleCol}>
                        {/* <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            PAYMENT CHARTS
                        </Typography> */}
                        <Paper style={{margin:'2%'}} elevation={2} >
                        <OrderC paid={weeklyChartPaid} notPaid={weeklyChartNotPaid}/>
                        </Paper>
                    </div>
                </div>

                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                    <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            LAST MONTH
                    </Typography>
                    </div>
                    <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Total number of Orders : {data.monthlyReport.totalNumberOfOrders}</p>
                                <p>Total number of Pending Orders : {data.monthlyReport.totalNumberOfPendingOrders}</p>
                                <p>Total number of Completed Orders : {data.monthlyReport.totalNumberOfCompletedOrders}</p>
                                <p>Total number of Cancelled Orders : {data.monthlyReport.totalNumberOfCancelledOrders}</p>
                            </div>
                        </Paper>
                    </div>

                    <div style={styles.singleCol}>
                        <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                            <h3 style={{display:'inline'}}>GROWTH IN ORDERS</h3>
                            <img src={green} alt="" style={{height:'5rem'}}/>
                            <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                                {data.monthlyReport.growthPercentage}%
                                {console.log("Paid from dashboard")}
                                {console.log(data.monthlyReport.paidPercentage.toString())}
                            </Typography>
                        </Paper>
                    </div>
                    <div style={styles.singleCol}>
                        {/* <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            PAYMENT CHARTS
                        </Typography> */}
                        
                        <Paper style={{margin:'2%'}} elevation={2} >
                        
                        <OrderC paid={monthlyChartPaid} notPaid={monthlyChartNotPaid}/>
                        </Paper>
                        
                    </div>
                </div>

            </div>
        </>
    );
};

export default DashboardInternalVendor;
