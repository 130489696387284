import * as React from "react";
import {
    List,
    Edit,
    Create,
    required,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    NumberInput,
    NumberField,
    BooleanField,
    BooleanInput,
    SimpleForm,
    Pagination,
    regex, ArrayInput, SimpleFormIterator, CheckboxGroupInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const SurveyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const SurveyList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List
        className={classes.root}
        perPage={50}
        pagination={<SurveyPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="label" />
            <BooleanField source="isActive" sortByOrder="ASC" />
            <NumberField source="sequenceNo" sortByOrder="ASC" />
            <EditButton />
        </Datagrid>
    </List>
);
}

const validateRelativeUrl = regex(/^\S*$/, 'Must not contain spaces');

const platform_choices = [
    { id: 'Alexa', name: 'Alexa' },
    { id: 'WebApp', name: 'WebApp' },
    { id: 'AndroidApp', name: 'AndroidApp' },
    { id: 'IosApp', name: 'IosApp' }
];

export const SurveyCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="label" validate={required()} fullWidth {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" validate={required()} choices={platform_choices} {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <BooleanInput source="isActive"/>
            <ArrayInput label="Questions" source="surveyQuestions" validate={required()}>
                <SimpleFormIterator>
                    <TextInput label="Title" source="title" validate={required()} fullWidth {...props}/>
                    <TextInput label="Question" source="question" validate={required()} fullWidth {...props}/>
                    <TextInput label="Button Title" source="buttonTitle" validate={required()} fullWidth {...props}/>
                    <BooleanInput label="allowMultiSelect" source="allowMultiSelect" {...props}/>
                    <ArrayInput label="Options" source="questionOptions">
                        <SimpleFormIterator>
                            <TextInput label="Answer" source="answer" validate={required()} fullWidth {...props}/>
                            <TextInput label="Image" source="image" validate={validateRelativeUrl} fullWidth {...props}/>
                            <TextInput label="CTA" source="cta" fullWidth {...props}/>
                            <BooleanInput label="isCustom" source="isCustom" {...props}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const SurveyEdit = props => {
    return(
    <Edit title={<SurveyTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} fullWidth {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" validate={required()} choices={platform_choices} {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <BooleanInput source="isActive"/>
            <ArrayInput label="Questions" source="surveyQuestions" validate={required()}>
                <SimpleFormIterator>
                    <TextInput label="Title" source="title" validate={required()} fullWidth {...props}/>
                    <TextInput label="Question" source="question" validate={required()} fullWidth {...props}/>
                    <TextInput label="Button Title" source="buttonTitle" validate={required()} fullWidth {...props}/>
                    <BooleanInput label="allowMultiSelect" source="allowMultiSelect" {...props}/>
                    <ArrayInput label="Options" source="questionOptions">
                        <SimpleFormIterator>
                            <TextInput label="Answer" source="answer" validate={required()} fullWidth {...props}/>
                            <TextInput label="Image" source="image" validate={validateRelativeUrl} fullWidth {...props}/>
                            <TextInput label="CTA" source="cta" fullWidth {...props}/>
                            <BooleanInput label="isCustom" source="isCustom" {...props}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
    );
};



const SurveyTitle = ({ record }) => {
    return <span> Post {record ? `"${record.label}"` : ''} </span>;
};