import {Box, CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {Filter, FilterList, SelectInput, TextInput} from "react-admin";
import * as React from "react";

export const tag_choices = [
    { id: 'qcBy', name: 'qcBy'},
    { id: 'qcDate', name: 'qcDate'},
    { id: 'seasons', name: 'seasons'},
    { id: 'miscTags', name: 'miscTags'},
    { id: 'cuisines', name: 'cuisines'},
    { id: 'holidays', name: 'holidays'},
    { id: 'inactive', name: 'inactive'},
    { id: 'hq_images', name: 'hq_images'},
    { id: 'beverages', name: 'beverages'},
    { id: 'dishTypes', name: 'dishTypes'},
    { id: 'mealTypes', name: 'mealTypes'},
    { id: 'occasions', name: 'occasions'},
    { id: 'categories', name: 'categories'},
    { id: 'recipes_5k', name: 'recipes_5k'},
    { id: 'skr_images', name: 'skr_images'},
    { id: 'heroSection', name: 'heroSection'},
    { id: 'specialCategories', name: 'specialCategories'},
    { id: 'subscription_info', name: 'subscription_info'}
]


export const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '1500000em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <TagFilter />
                <TagValueFilter />
            </CardContent>
        </Card>
    </Box>
);

const TagFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Tag" source="tag" choices={tag_choices} alwaysOn/>
    </Filter>
);

const TagValueFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Tag Value" source="tag_value" alwaysOn/>
    </Filter>
);