import * as React from "react";
import {
    List,
    Edit,
    Create,
    required,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    NumberInput,
    NumberField,
    BooleanField,
    BooleanInput,
    SimpleForm,
    Pagination,
    regex, ArrayInput, SimpleFormIterator, CheckboxGroupInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const countryType = [
    { id: 'IN', name: 'India' },
    { id: 'US', name: 'USA' },
    { id: 'CA', name: 'Canada' },
    { id: 'GB', name: 'Great Britain' },
    { id: 'AU', name: 'Australia' },
    { id: 'IT', name: 'Italy' },
];

const platform = [
    { id: 'Alexa', name: 'Alexa' },
    { id: 'GoogleHome', name: 'GoogleHome' },
    { id: 'WebApp', name: 'WebApp' },
    { id: 'AndroidApp', name: 'AndroidApp' },
    { id: 'IosApp', name: 'IosApp' }
];

const platformVendor = [
    { id: 'KloveChef', name: 'KloveChef' },
    { id: 'NutriPot', name: 'NutriPot' },
    { id: 'NutriBlend', name: 'NutriBlend' },
    { id: 'SanjeevKapoor', name: 'SanjeevKapoor' },
    { id: 'TataSampann', name: 'TataSampann' },
    { id: 'ckbk', name: 'ckbk' },
    { id: 'RobertRose', name: 'RobertRose' },
    { id: 'Sottilette', name: 'Sottilette' },
    { id: 'KanaksKitchen', name: 'KanaksKitchen' }
];

const platformLanguage = [
    { id: 'hi', name: 'hi' },
    { id: 'en', name: 'en' }
];

const DealsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const DealsList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List
        className={classes.root}
        perPage={50}
        pagination={<DealsPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="dealTitle" />
            <TextField source="dealCategory" />
            <BooleanField source="isActive" sortByOrder="ASC" />
            <TextField source="countryType" />
            <NumberField source="sequenceNo" sortByOrder="ASC" />
            <EditButton />
        </Datagrid>
    </List>
);
}

const validateRelativeUrl = regex(/^\S*$/, 'Must not contain spaces');


export const DealsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="dealTitle" validate={required()} fullWidth {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <TextInput source="dealLink" validate={[required(), validateRelativeUrl]} fullWidth {...props}/>
            <TextInput source="price" fullWidth {...props}/>
            <TextInput source="backgroundImageUrl" validate={[required(), validateRelativeUrl]} fullWidth {...props}/>
            <BooleanInput source="isActive"/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" choices={platform} {...props}/>
            <CheckboxGroupInput label="PlatformVendors" source="platformVendorList" choices={platformVendor} {...props}/>
            <CheckboxGroupInput label="PlatformLanguages" source="platformLanguageList" choices={platformLanguage} {...props}/>
            <TextInput source="dealCategory" fullWidth {...props}/>
            <TextInput source="dealCategoryDisplayText" fullWidth {...props}/>
            <TextInput source="dealCategoryImageUrl" validate={validateRelativeUrl} fullWidth {...props}/>
            <TextInput source="backgroundColor" fullWidth {...props}/>
            <TextInput source="companyImageUrl" validate={validateRelativeUrl} fullWidth {...props}/>
            <TextInput source="companyText" fullWidth {...props}/>
            <TextInput source="dealDescription" fullWidth {...props}/>
            <TextInput source="dealCode" fullWidth {...props}/>
        </SimpleForm>
    </Create>
);

export const DealsEdit = props => {
    return(
    <Edit title={<DealsTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="dealTitle" validate={required()} fullWidth {...props}/>
            <NumberInput label="Order No" source="sequenceNo"/>
            <TextInput source="dealLink" validate={[required(), validateRelativeUrl]} fullWidth {...props}/>
            <TextInput source="price" fullWidth {...props}/>
            <TextInput source="backgroundImageUrl" validate={[required(), validateRelativeUrl]}  fullWidth {...props}/>
            <BooleanInput source="isActive"/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} {...props}/>
            <CheckboxGroupInput label="Platforms" source="platformList" choices={platform} {...props}/>
            <CheckboxGroupInput label="PlatformVendors" source="platformVendorList" choices={platformVendor} {...props}/>
            <CheckboxGroupInput label="PlatformLanguages" source="platformLanguageList" choices={platformLanguage} {...props}/>
            <TextInput source="dealCategory" fullWidth {...props}/>
            <TextInput source="dealCategoryDisplayText" fullWidth {...props}/>
            <TextInput source="dealCategoryImageUrl" validate={validateRelativeUrl} fullWidth {...props}/>
            <TextInput source="backgroundColor" fullWidth {...props}/>
            <TextInput source="companyImageUrl" validate={validateRelativeUrl} fullWidth {...props}/>
            <TextInput source="companyText" fullWidth {...props}/>
            <TextInput source="dealDescription" fullWidth {...props}/>
            <TextInput source="dealCode" fullWidth {...props}/>
        </SimpleForm>
    </Edit>
    );
};



const DealsTitle = ({ record }) => {
    return <span> Post {record ? `"${record.dealTitle}"` : ''} </span>;
};