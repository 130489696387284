// import fire from './firebase'

const authProvider = {

    // login: ({email, password}) =>{
    //     fire
    //     .auth()
    //     .signInWithEmailAndPassword(email, password)
    //     .catch(err =>{
    //         switch(err.code){
    //             case "auth/invalid-email":
    //             case "auth/user-not-found":
    //             case "auth/user-disabled":
    //                 throw new Error(err.message);
    //                 break;
    //         }
    //     })

    //     return Promise.resolve;
    //}


    login:({ email, password }) =>  {
        // var obj = new Object();
        // obj.email = "swaggerUser";
        // obj.password  = "GbMG$&jMz39N";
        // var jsonString= JSON.stringify(obj);
        // console.log(jsonString);
        // console.log(email);
        // console.log(password);
        const request = new Request(process.env.REACT_APP_BASE_URL+'zelish/auth/login', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            // body: jsonString,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                console.log(response);
                Promise.resolve();
                return response.json();
                
            })
            // .then( => {
            //     // const decodedToken = decodeJwt(token);
            //     // localStorage.setItem('token', token);
                 
            // })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
                localStorage.setItem('permissions', auth.data.roles[0].role);
                localStorage.setItem('vendorId',auth.data.vendorId);
                localStorage.setItem('recipeWriterId',auth.data.recipeWriterId);
                console.log(auth.data.roles[0].role);
                console.log(auth.data.vendorId);
                console.log(auth);
            });
    },
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
        // return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;