// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Theme, Box } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import { AppState } from '../../types';
import ListAltIcon from '@material-ui/icons/ListAlt';

import {
    DashboardMenuItem,
    MenuItemLink,
    getResources,
    usePermissions
} from 'react-admin';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ onMenuClick, logout, dense = false }:any) => {
    const {permissions } = usePermissions();
    const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            {permissions === 'VENDOR_ADMIN' &&
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            }
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}

            {permissions === 'OPS_ADMIN' &&
            <MenuItemLink
                to="/paymentSummary"
                primaryText="Summary"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />}


            {permissions === 'RECIPE_ADMIN' &&
            <MenuItemLink
                to="/recipe"
                primaryText="Recipe"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />},

            {permissions === 'RECIPE_ADMIN' &&
                <MenuItemLink
                to="/recipeSummary"
                primaryText="Recipe Summary"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                />}

            {isXSmall && logout}
        </div>
    );
};

export default Menu;