import * as React from "react";
import { List, Datagrid, TextField, NumberField, EditButton, TextInput,
NumberInput, Edit, SimpleForm, Create, Filter, ReferenceInput, SelectInput, UrlField } from 'react-admin';


export const AuthorList = props => (
    <List filters={<AuthorFilter />} {...props}>
        <Datagrid>
            <TextField source="authorId" />
            <TextField source="sequenceNo" />
            <TextField source="name" />
            <UrlField source="imageURL" />
            <TextField source="bio" />
            <NumberField source="dishCount" />
            <TextField source="companyName" />
            <UrlField source="companyURL" />
            <EditButton />
        </Datagrid>
    </List>
);

export const AuthorEdit = props => (
    <Edit title={<AuthorBio/>} {...props}>
        <SimpleForm >
            <TextInput disabled source="id" />
            <TextInput source="authorId" />
            <TextInput source="sequenceNo" />
            <TextInput source="name" />
            <TextInput source="imageURL" />
            <TextInput source="bio" />
            <NumberInput source="dishCount" />
            <TextInput source="companyName" />
            <TextInput source="companyURL" />
        </SimpleForm>
    </Edit>
);

export const AuthorCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="authorId" />
            <TextInput source="sequenceNo" />
            <TextInput source="name" />
            <TextInput source="imageURL" />
            <TextInput source="bio" />
            <NumberInput source="dishCount" />
            <TextInput source="companyName" />
            <TextInput source="companyURL" />
        </SimpleForm>
    </Create>
);

const AuthorFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Name" source="id" reference="Author" allowEmpty>
            <SelectInput optionText="Name" />
        </ReferenceInput>
    </Filter>
);

const AuthorBio = ({ record }) => {
    return <span> {record ? `${record.name} : ${record.bio}` : ''} </span>;
};
