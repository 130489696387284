import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    Edit,
    SimpleForm,
    Create,
    Pagination,
    required,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { getImageData } from './imageHandler'

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
  }));

const ImageUploadPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ImageUploadList = ({permissions,...props}) => {
const classes = useStyles();

    return(
    <List
        className={classes.root}
        perPage={50}
        pagination={<ImageUploadPagination />}
        sort={{ field: "sequenceNo", order: "ASC" }}
        {...props}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="recipeGuid"/>
            <EditButton/>
        </Datagrid>
    </List>
);
}


const bucket_choices = [
    { id: 'recipe-assets', name: 'recipe-assets'},
    { id: 'sk-recipe-assets', name: 'sk-recipe-assets'},
    { id: 'kc-recipe-assets', name: 'kc-recipe-assets'},
    { id: 'user-recipe-assets', name: 'user-recipe-assets'},
]


export const ImageUploadCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="bucketName" validate={required()} choices={bucket_choices} {...props}/>
            <TextInput source="recipeGuid" validate={required()} {...props}/>
            <ImageInput source="pictures" label="Recipe Image" accept="image/png, image/jpg, image/jpeg" validate={required()}>
                <ImageField source="imageData"/>
            </ImageInput>
            <FormDataConsumer>
                {({formData, dispatch, ...rest}) => {
                    
                    if (formData.pictures && formData.pictures.rawFile) {
                        let base64 = getImageData(formData);
                        base64.then((result) => {
                            return (
                                formData.imageData = result
                            );
                        })                        
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);


export const ImageUploadEdit = props => {
    return(
    <Edit title={<ImageUploadTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" validate={required()}/>
            <SelectInput disabled source="bucketName" validate={required()} choices={bucket_choices} {...props}/>
            <TextInput disabled source="recipeGuid" validate={required()} {...props}/>
            <ImageInput disabled source="pictures" label="Recipe Image" accept="image/png, image/jpg, image/jpeg" validate={required()}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageField source="imageData" label="Previous Uploaded Image"/>
            <FormDataConsumer>
                {({formData, dispatch, ...rest}) => {
                    
                    if (formData.pictures && formData.pictures.rawFile) {
                        let base64 = getImageData(formData);
                        base64.then((result) => {
                            return (
                                formData.imageData = result
                            );
                        })                        
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
    );
};

const ImageUploadTitle = ({ record }) => {
    return <span> Post {record ? `"${record.recipe_guid}"` : ''} </span>;
};