import * as React from 'react';
import { FC } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
    value2?:number;
}

const WeeklySummary: FC<Props> = ({ value,value2 }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/Order"
            icon={DateRangeIcon}
            title={translate('Weekly Summary')}
            subtitle={"Total Order : "+value?.toString()}
            subtitle_2={"Total Amount : "+value2?.toString()}
        />
    );
};

export default WeeklySummary;
