import React, {
    CSSProperties, useState
} from 'react';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import { useForm } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import {useQuery, Loading, AutocompleteInput, ReferenceInput, SimpleForm} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import green from '../assets/img/dashboard/Green_U.png'




import {RecipeC} from './RecipeChart';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    //   alignItems: 'stretch',
      '& > *': {
        margin: '2%',
        padding:'5%',
        width: '100%',
        height: 'auto',
      },
    },
    orderData:{
        marginLeft: '1%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '5%',
        marginTop:'5%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
  }));


const styles = {
    flex: { display: 'flex',alignItems: 'stretch' },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    chart: {width:'100%', height: 400, margin:'1%' }
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const DashboardRecipeWriter = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const classes = useStyles();

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: "RecipeDashboard",
        payload: {id:"RW001"}
    });

    if (loading) return <Loading />;
    if (error) return '';
    if (!data) return null;  
    console.log(data);


    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                

                
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
            </div>
            <div style={styles.flex}>
                <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                    PAYMENT SUMMARY
                </Typography>
                <Spacer />

               
            </div>
            <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Payment Received Last Week : {data.paymentReceivedThisWeek}</p>
                                <p>Payment Received Last Month : {data.paymentReceivedThisMonth}</p>
                                <p>Payment Received Till Date : {data.paymentReceivedThisDate}</p>
                                <p style={{color:'white'}}>_</p>
                            </div>
                        </Paper>
            </div>
            
            <div style={styles.singleCol}>
                <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                    <h3 style={{display:'inline'}}>GROWTH</h3>
                    <img src={green} alt="" style={{height:'5rem'}}/>
                    <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                    {data.paymentGrowth}%
                    </Typography>
                </Paper>
            </div>
            <div style={styles.singleCol}>
                <Paper style={{margin:'2%'}} elevation={2} >
                
                </Paper>
                <RecipeC record={data}/>
            </div>


            <div style={styles.flex}>
                <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                ORDER SUMMARY
                </Typography>
                <Spacer />
            </div>
            <div className={classes.root}>
                <Paper elevation={2} >
                    <div className={classes.orderData}>
                        <p>Order Received Today : {data.ordersReceivedToday}</p>
                        <p>Order Received This Week : {data.ordersReceivedThisWeek}</p>
                        <p>Order Received This Month : {data.ordersReceivedThisMonth}</p>
                        <p>Order Received Till Date : {data.ordersReceivedTillDate}</p>
                    </div>
                </Paper>
            </div>
            <div style={styles.singleCol}>
                <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                    <h3 style={{display:'inline'}}>GROWTH</h3>
                    <img src={green} alt="" style={{height:'5rem'}}/>
                    <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                        {data.orderGrowth}%
                    </Typography>
                </Paper>
            </div>
            <div style={styles.singleCol}>
                <Paper style={{margin:'2%'}} elevation={2} >
                
                </Paper>
            </div>
        </div>
    ) : (
        <>
            <div style={styles.flex}>
            
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            PAYMENT SUMMARY
                        </Typography>
                        <Spacer />
                         
                    </div>
                    <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Payment Received Last Week : {data.paymentReceivedThisWeek}</p>
                                <p>Payment Received Last Month : {data.paymentReceivedThisMonth}</p>
                                <p>Payment Received Till Date : {data.paymentReceivedTillDate}</p>
                                <p style={{color:'white'}}>_</p>
                            </div>
                        </Paper>
                    </div> 
                    
                    <div style={styles.singleCol}>
                        
                        <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                            <h3 style={{display:'inline'}}>GROWTH</h3>
                            <img src={green} alt="" style={{height:'5rem'}}/>
                            <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                            {data.paymentGrowth}%
                            </Typography>
                        </Paper>
                        
                    </div>

                    <div style={styles.singleCol}>
                        <Paper elevation={2}  style={{margin:'2%'}}>
                        <RecipeC record={data}/>
                        </Paper>
                    </div>
                    
                </div>

                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                    <Typography variant="h3" gutterBottom style={{width:'100%',textAlign:'center'}}>
                            ORDER SUMMARY
                    </Typography>
                    </div>
                    <div className={classes.root}>
                        <Paper elevation={2} >
                            <div className={classes.orderData}>
                                <p>Order Received Today : {data.ordersReceivedToday}</p>
                                <p>Order Received This Week : {data.ordersReceivedThisWeek}</p>
                                <p>Order Received This Month : {data.ordersReceivedThisMonth}</p>
                                <p>Order Received Till Date : {data.ordersReceivedTillDate}</p>
                            </div>
                        </Paper>
                    </div>

                    <div style={styles.singleCol}>
                        <Paper elevation={2} style={{padding:'2%',display:'flex',justifyContent: 'space-around',margin:'2%'}} >
                            <h3 style={{display:'inline'}}>GROWTH</h3>
                            <img src={green} alt="" style={{height:'5rem'}}/>
                            <Typography variant="h4" gutterBottom style={{color:'green',display:'inline',alignContent:'center'}}>
                            {data.orderGrowth}%
                            </Typography>
                        </Paper>
                    </div>
                </div>

            </div>

            
        </>
    );
};

export default DashboardRecipeWriter;
