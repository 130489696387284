import * as React from 'react';
import { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Input,InputLabel } from "@material-ui/core";

// core components
// import HeaderLinks from "../components/Header/HeaderLinks.js";
// import Footer from "../components/Footer/Footer.js";
import GridContainer from "../components/loginPage/Grid/GridContainer.js";
import GridItem from "../components/loginPage/Grid/GridItem.js";
import Button from "../components/loginPage/CustomButtons/Button.js";
import Card from "../components/loginPage/Card/Card.js";
import CardBody from "../components/loginPage/Card/CardBody.js";
import CardHeader from "../components/loginPage/Card/CardHeader.js";
import CardFooter from "../components/loginPage/Card/CardFooter.js";
import logo from "../assets/img/logo.png";


import styles from "../assets/jss/loginPage.js";

import image from "../assets/img/bg.jpg";

const useStyles = makeStyles(styles);

const ResetPassword = (props) => {
    const [email, setEmail] = useState('');
    const [existingPassword, setExisting] = useState('');
    const [newPassword, setPassword] = useState('');
    const [PasswordError, setError] = useState('');
    const [PasswordSuccess, setSuccess] = useState('');

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
        setCardAnimation("");
      }, 700);
    const classes = useStyles();
    

    const passwordReset = ({email,existingPassword,newPassword}) => {
        const request = new Request(process.env.REACT_APP_BASE_URL+'zelish/auth/password/update', {
            method: 'POST',
            body: JSON.stringify({ email,existingPassword,newPassword }),
            // body: jsonString,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                setSuccess("Password changed.")
                console.log(response);
                Promise.resolve();
                return response.json();
                
            })
    }

    const submit = e => {
        e.preventDefault();
        passwordReset({ email,existingPassword,newPassword }).catch(() =>
            setError('Invalid email or password')
        );
    };

    return (
      <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                  <div >
                    <img src={logo} alt="Logo" />
                  </div>
                    <h3>Password Reset</h3>
                  </CardHeader>
                  <CardBody>

                    <FormControl className={classes.forminput}>
                      <InputLabel> Email</InputLabel>
                      <Input onChange={e => setEmail(e.target.value)}></Input> 
                    </FormControl>

                    <FormControl className={classes.forminput}>
                    <InputLabel> Existing Password</InputLabel>
                      <Input onChange={e => setExisting(e.target.value)}></Input>
                    </FormControl>
                    
                    <FormControl className={classes.forminput}>
                    <InputLabel> New Password</InputLabel>
                      <Input onChange={e => setPassword(e.target.value)}></Input>
                    </FormControl>

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button onClick={submit} simple color="primary" size="lg">
                      Submit
                    </Button>
                  </CardFooter>
                  <div style={{textAlign:'center',color:'red'}}>
                    {PasswordError}
                  </div>
                  <div style={{textAlign:'center',color:'green'}}>
                    {PasswordSuccess}
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
    );
}

export default ResetPassword;