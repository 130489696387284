import * as React from "react";
import { List, Datagrid, TextField, EditButton, TextInput, BooleanInput, CheckboxGroupInput, NumberInput, 
Edit, SimpleForm, Create, Filter, Pagination, ReferenceInput, SelectInput, UrlField, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const countryType = [
    { id: 'IN', name: 'India' },
    { id: 'US', name: 'USA' },
    { id: 'CA', name: 'Canada' }
];

const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)',
      },
    butt:{
    '& > *': {
        margin: theme.spacing(2),
        },
    }
    },
}));

const IngPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const IngList = ({permissions,...props}) => {
    const classes = useStyles();

    return(
    <List className={classes.root} filters={<IngFilter />} {...props} perPage={50} title="New list of Ingredient" sort="sequenceNo" pagination={<IngPagination />}>
        <Datagrid>
            <TextField source="label" />
            <TextField source="name" label="Ingredient Name" />
            <TextField source="ingredientId" label="Ingredient ID"/>
            <TextField source="sequenceNo" label="Sequence No"/>
            <UrlField source="imageURL" label="Image URL"/>
            <EditButton />
        </Datagrid>
    </List>
    );
};

export const IngEdit = props => {
    //const classes = useStyles();
    return(
    <Edit title={<IngTitle/>} {...props}>
        <SimpleForm >
            <TextInput disabled source="id" validate={required()}/>
            <TextInput source="label" validate={required()} />
            <TextInput source="name" />
            <NumberInput source="sequenceNo" validate={required()} label="order No"/>
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} defaultValue={["IN", "US", "CA"]} />
            <BooleanInput source="isVegetarian" validate={required()}/>
            <BooleanInput source="isActive" validate={required()}/>
            <TextInput source="ingredientId" label="ingredientId: Can't fill this" />
            <TextInput source="imageURL" label="Image URL" />
        </SimpleForm>
    </Edit>
    );
};

export const IngCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="label" validate={required()} />
            <TextInput source="name" label="Ingredient name" />
            <NumberInput source="sequenceNo" />
            <CheckboxGroupInput label="Country" source="countryType" choices={countryType} defaultValue={["IN", "US", "CA"]} />
            <BooleanInput source="isVegetarian" validate={required()}/>
            <BooleanInput source="isActive" validate={required()}/>
            <TextInput disabled source="ingredientId" label="ingredientId: Don't fill this" />
            <TextInput source="imageURL" label="Image URL" />
        </SimpleForm>
    </Create>
);

const IngFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="label" source="page" reference="Ing" allowEmpty>
            <SelectInput optionText="label" />
        </ReferenceInput>
    </Filter>
);

const IngTitle = ({ record }) => {
    return <span> Post {record ? `"${record.label}"` : ''} </span>;
};