import React, {PureComponent, FC} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer,Legend } from 'recharts';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { useQuery } from 'react-admin';


const RADIAN = Math.PI / 180; 
const dataa = [{name: 'COMPLETED', value: 0}, {name: 'PENDING', value: 0}];
const COLORS = ['#0088FE', '#00C49F'];



const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }:any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);
  
    return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
    </text>
    );
};


class RecipeChart extends PureComponent {
	constructor(props?:any){
		super(props);
	}
	render() {
	return (
			<Card>
            <CardHeader title= "Order Status Summary" />
            <CardContent>
                <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
                <PieChart >
                    <Pie
                        data={dataa}
                        cx={200}
                        cy={200}
                        label={renderCustomizedLabel}
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {
                            dataa.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Legend  height={36}/>
                </PieChart>
			</ResponsiveContainer>
			{console.log("This the chart data")}
                </div>
            </CardContent>
        </Card>
		);
	}
}

export const RecipeC = ({record}:any) => {

    if(record.completedOrderPercentage === 0){
        dataa[0].value = 100;
    }else {
        dataa[0].value = record.completedOrderPercentage;
    }

    if(record.pendingOrderPercentage === 0){
        dataa[1].value = 50;
    }else {
        dataa[1].value = record.pendingOrderPercentage;
    }    

	return(<RecipeChart/>)
}
