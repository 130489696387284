import React, {
    CSSProperties
} from 'react';

import {useQuery, Loading} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';

import Welcome from './Welcome';
import DailySummary from './DailySummary';
import WeeklySummary from './WeeklySummary';
import MonthlySummary from './MonthlySummary';

import {PaymentC} from './PaymentChart';
import {GrowthC} from './GrowthChart';

import TotalSummary from './TotalSummary';



const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const vendor_id = localStorage.getItem('vendorId')

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: "VendorReport",
        payload: { id: vendor_id }
    });

    if (loading) return <Loading />;
    if (error) return '';
    if (!data) return null;
    
    console.log("Data is fetched:");
    console.log(data);
    console.log(typeof(data.totalOrders));
    console.log(data.dailySummary);
    console.log(data.dailySummary.totalOrders);

    
    // return (<TotalSummary value={data.totalOrders} />)

    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount}/>
                <VerticalSpacer />
                <WeeklySummary value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}  />
                <VerticalSpacer />
                <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                <VerticalSpacer />
                <TotalSummary value={data.totalOrders} value2={data.totalAmount}/>
                
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
                <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount}/>
                <Spacer />
                <WeeklySummary value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}/>
                <Spacer />
                <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                <Spacer />
                <TotalSummary value={data.totalOrders} value2={data.totalAmount}/>
            </div>
            <div style={styles.singleCol}>
                <PaymentC record={data}/>
            </div>
            <div style={styles.singleCol}>
                {/* <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                /> */}
                <GrowthC record={data}/>
            </div>
        </div>
    ) : (
        <>
            <Welcome />
            <div style={styles.flex}>
                
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <DailySummary valueordr={data.dailySummary.totalOrders} valueamt={data.dailySummary.totalAmount} />
                        <Spacer />
                        <WeeklySummary  value={data.weeklySummary.totalOrders} value2={data.weeklySummary.totalAmount}/>
                    </div>
                    <div style={styles.singleCol}>
                        <PaymentC record={data} />
                    </div>
                </div>

                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <MonthlySummary value={data.monthlySummary.totalOrders} value2={data.monthlySummary.totalAmount}/>
                        <Spacer />
                        <TotalSummary value={data.totalOrders} value2={data.totalAmount}/>
                    </div>

                    <div style={styles.singleCol}>
                        <GrowthC record={data}/>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Dashboard;
