import React, {PureComponent, FC} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer,Legend } from 'recharts';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { useQuery } from 'react-admin';



const dataa = [{name: 'ONLINE', value: 400}, {name: 'CASH', value: 300}];
const COLORS = ['#0088FE', '#00C49F'];
// const translate = useTranslate();
const RADIAN = Math.PI / 180; 



const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }:any) => {
 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);
 
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
    	{`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


class PaymentChart extends PureComponent {
	constructor(props?:any){
		super(props);
		
	}
	render() {
	return (
			<Card>
            <CardHeader title= "Payment Chart" />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
			<PieChart width={400} height={400}>
				<Pie
					data={dataa}
					cx={200}
					cy={200}
					label={renderCustomizedLabel}
					labelLine={false}
					outerRadius={80}
					fill="#8884d8"
					dataKey="value"
				>
					{
						dataa.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
					}
				</Pie>
				<Legend  height={36}/>
			</PieChart>
			</ResponsiveContainer>
			{console.log(this.props)}
			{console.log("This the chart data")}
                </div>
            </CardContent>
        </Card>
		);
	}
}

// interface LabelType {
//   cx: number;
//   cy: number;
//   midAngle: number;
//   innerRadius: number;
//   outerRadius: number;
//   percent: number;
//   index: number;
// }

export const PaymentC = ({record}:any) => {

	dataa[0].value = record.onlinePercentage;
	dataa[1].value = record.cashPercentage;
	console.log(record.onlinePercentage);

	return(<PaymentChart/>)
}
